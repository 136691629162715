<template>
  <div class="app">
    <el-card class="box-card" v-if="exportOrImportStatus == 0">
      <!--列表-->
      <div v-if="!addStatus">
        <div class="listsHeader">
          <el-row :gutter="24">
            <el-col :span="12">
              <el-popover
                placement="top"
                width="160"
                v-model="delAllStatus"
                style="margin-right: 8px;"
              >
                <p>您確認要刪除嗎？</p>
                <div style="text-align: right; margin: 0">
                  <el-button type="text" size="small" @click="delAllStatus = false">取消</el-button>
                  <el-button type="primary" size="small" @click="doRecycleAll">确定</el-button>
                </div>
                <el-button type="warning" size="small" slot="reference">
                  <i class="fa fa-recycle"></i> 批量刪除
                </el-button>
              </el-popover>

              <el-button
                type="info"
                size="small"
                @click="$refs.RoomTable.clearSelection()"
                style="margin-right: 8px;"
              >取消選擇</el-button>

              <el-dropdown split-button type="success" size="small" @command="excelClick">
                更多
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="export"><i class="fa fa-file-excel-o"></i> 導出數據</el-dropdown-item>
                  <el-dropdown-item command="import"><i class="fa fa-file-excel-o"></i> 導入數據</el-dropdown-item>
                  <el-dropdown-item command="download"><i class="fa fa-download "></i> 下载模板</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </el-col>

            <el-col :span="12" v-if="!advancedQuery">
              <el-input placeholder="查詢：發票號碼、姓名、會員號、手提號碼" v-model="queryDb.query" class="employees-input">
                <div slot="append" class="queryBtn">
                  <el-button  @click="queryBin">
                    <i class="fa fa-search"></i>
                  </el-button>
                  <el-button class="bg-primary" @click="advancedQuery =! advancedQuery">
                    高級
                  </el-button>
                </div>
              </el-input>

            </el-col>
            <el-col :span="24" class="advancedQuery" v-if="advancedQuery">
              <el-col :span="4">
                <el-select v-model="queryDb.storeType" filterable placeholder="選擇門店">
                  <el-option label="所有門店" value="0"></el-option>
                  <el-option
                    v-for="item in storeList"
                    :key="item.id"
                    :label="item.label+` ( ID:`+item.id+` )`"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-col>
              <el-col :span="4">
                <el-select v-model="queryDb.teamType" filterable  placeholder="選擇運營">
                  <el-option label="所有運營人員" value="0"></el-option>
                  <el-option
                    v-for="item in operatingUserList"
                    :key="item.id"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-col>
              <el-col :span="8">
                <el-date-picker
                  v-model="queryDb.date"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期" style="width: 100%;">
                </el-date-picker>
              </el-col>


              <el-col :span="8">
                <el-input placeholder="查詢：發票號碼、姓名、會員號、手提號碼" v-model="queryDb.query" class="employees-input">
                  <div slot="append" class="queryBtn">
                    <el-button @click="queryBin">
                      <i class="fa fa-search"></i>
                    </el-button>
                    <el-button class="bg-warning" @click="advancedQuery =! advancedQuery">
                      簡易
                    </el-button>
                  </div>
                </el-input>
              </el-col>


            </el-col>


          </el-row>
        </div>
        <el-table
          class="lists"
          ref="RoomTable"
          :data="data.list"
          :height="data.total?clientHeight:'100'"
          v-loading="loading"
          @selection-change="handleSelectionChange"
          stripe
          style="width: 100%;"
        >
          <el-table-column fixed type="selection" width="50"></el-table-column>
          <el-table-column fixed prop="no" min-width="110" label="Invoice.No"></el-table-column>
          <el-table-column prop="storeName" min-width="80" label="門店"></el-table-column>
          <el-table-column prop="date" label="日期" width="100"></el-table-column>
          <el-table-column prop="name" label="會員姓名" width="120"></el-table-column>
          <el-table-column prop="memberId" label="會員編號" width="180"></el-table-column>
          <el-table-column prop="phones" label="Tel No" width="120"></el-table-column>
          <el-table-column prop="total" label="全數" ></el-table-column>
          <el-table-column prop="collection" label="代收7% 銀行分期" ></el-table-column>
          <el-table-column prop="oldCustomersPaidIn" label="舊客 實收" ></el-table-column>
          <el-table-column prop="oldCustomersInterimAmountOrMantissa" label="舊客 中期數／尾數"></el-table-column>
          <el-table-column prop="newCustomersPaidIn" label="新客 實收" width="180"></el-table-column>
          <el-table-column prop="newCustomersInterimAmountOrMantissa" label="新客 中期數／尾數"></el-table-column>
          <el-table-column prop="holdPayment" label="Hold Payment"></el-table-column>
          <el-table-column prop="mantissa" label="尾數"></el-table-column>
          <el-table-column prop="purchaseContent" label="購買內容" width="200"></el-table-column>
          <el-table-column prop="consultant" label="顧問" width="180"></el-table-column>
          <el-table-column prop="beautician" label="美容師" width="180"></el-table-column>
          <el-table-column prop="adminName" label="最近操作者" width="180"></el-table-column>
          <el-table-column prop="operationTime" label="最近操作時間" width="180"></el-table-column>
          <el-table-column fixed="right" label="操作" width="130">
            <template slot-scope="scope">
              <div style="text-align: center;">
                <el-dropdown
                  split-button
                  type="primary"
                  size="small"
                  @command="actionBtn">
                  <span @click="addToEdit(scope.row)">
                    <i class="el-icon-edit-outline" style="font-weight: 900;"></i> 編輯
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                      style="color: #F56C6C;"
                      :command="{id:scope.row.id,name:'recycle'}">
                      <i class="fa fa-trash-alt"></i>刪除
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </template>
          </el-table-column>
        </el-table>

        <div class="pager">
          <el-pagination
          v-if="isPages"
            background
            layout="total, prev, pager, next"
            @current-change="pageBtn"
            :page-size="data.pageSize"
            :total="data.total"
            :hide-on-single-page="true"
          ></el-pagination>
        </div>
      </div>

      <!--添加/编辑-->
      <div v-else>

        <div class="listsHeader">
          <el-row :gutter="24">
            <el-col :span="11">
              <el-button size="mini" type="warning" @click="addToEdit">
                <i class="fa fa-reply"></i> 返回
              </el-button>
            </el-col>
            <el-col :span="13">{{editStatus?'編輯用戶':'添加用戶'}}</el-col>
          </el-row>
        </div>


        <el-row :gutter="24">
          <el-form
            :model="form"
            :rules="rules"
            ref="ruleForm"
            label-width="100px"
            class="member-ruleForm"
          >
            <el-col :span="12">
              <el-form-item label="發票號碼" prop="no">
                <el-input placeholder="請填寫發票號碼" v-model="form.no"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="會員編號" prop="memberId">
                <el-input placeholder="請填寫會員編號" v-model="form.memberId"></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="會員姓名" prop="name">
                <el-input placeholder="請填寫會員姓名" v-model="form.name"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="手提號碼" prop="phones">
                <el-input placeholder="請填寫手提號碼" v-model="form.phones"></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="門店" prop="storeId">
                <el-select v-model="form.storeId" placeholder="請選擇" style="width: 100%;">
                  <el-option
                    v-for="item in storeList"
                    :key="item.id"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="日期" prop="date">
              <el-date-picker
                v-model="form.date"
                type="date"
                placeholder="選擇日期"
                style="width: 100%;">
              </el-date-picker>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="舊客實收">
                <el-input placeholder="請填寫舊客實收" v-model="form.oldCustomersPaidIn"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="舊客中期數">
                <el-input placeholder="請填寫舊客中期數／尾數" v-model="form.oldCustomersInterimAmountOrMantissa"></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="新客實收">
                <el-input placeholder="請填寫舊客實收" v-model="form.newCustomersPaidIn"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="新客中期數">
                <el-input placeholder="請填寫舊客中期數／尾數" v-model="form.newCustomersInterimAmountOrMantissa"></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="全數">
                <el-input placeholder="請填寫全數" v-model="form.total"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="代收7%">
                <el-input placeholder="請填寫代收7% 銀行分期" v-model="form.collection"></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="暫緩付款">
                <el-input placeholder="請填寫Hold Payment" v-model="form.holdPayment"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="尾數">
                <el-input placeholder="請填寫尾數" v-model="form.mantissa"></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="顧問">
                <el-input placeholder="請填寫顧問" v-model="form.consultant"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="美容師">
                <el-input placeholder="請填寫美容師" v-model="form.beautician"></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="最近操作者">
                <el-input v-model="form.adminName" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="最近操作時間">
                <el-input v-model="form.operationTime" :disabled="true"></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="24">
              <el-form-item label="購買內容">
                <el-input
                  type="textarea"
                  :rows="6"
                  placeholder="请输入内容"
                  v-model="form.purchaseContent">
                </el-input>
              </el-form-item>
            </el-col>




            <el-col :span="24">
              <div
                style="color: #C03639;font-size: 13px;text-align:left;float: left;"
                v-if="editStatus"
              ></div>
              <div style="text-align: right;">
                <el-button @click="resetForm()">重置</el-button>
                <el-button
                  type="primary"
                  @click="submitForm('ruleForm')"
                >{{editStatus?'立即編輯':'立即添加'}}</el-button>
              </div>
            </el-col>
          </el-form>
        </el-row>
      </div>
    </el-card>
    <!--导入文件-->
    <el-card class="box-card" v-else-if="exportOrImportStatus == 1">
      <div class="loading-bg" v-if="loadingDb.status == 0">
        <el-image style="height: 300px" :src="loading1" fit="fit"></el-image>
        <p>{{loadingDb.title?loadingDb.title:"正在處理數據，請耐心等候..."}}</p>
      </div>
      <div class="loading-bg"  v-else-if="loadingDb.status == 1">
        <i class="fa fa-check-circle color-success" style="font-size: 100px;"></i>
        <p>{{loadingDb.title}}</p>
        <el-button size="small" type="warning" @click="returnBtn">返回</el-button>
        <el-button size="small" type="success" @click="downloadFile">下載文件</el-button>
      </div>
      <div class="loading-bg"  v-else-if="loadingDb.status >= 2">
        <i class="fa fa-times color-danger" style="font-size: 100px;"></i>
        <p style="font-size: 13px;">{{loadingDb.title}}</p>
        <el-button size="small" type="warning" @click="returnBtn">返回</el-button>
      </div>
    </el-card>

    <!--导出文件-->
    <el-card class="box-card" v-else-if="exportOrImportStatus == 2">
      <div class="loading-body">
        <div class="loading-bg" v-if="loadingDb.status == 0">


  <!--        <el-upload-->
  <!--          class="upload-demo"-->
  <!--          drag-->
  <!--          action="/v1/booking/uploadImportFile"-->
  <!--          name="file"-->
  <!--          :show-file-list="false"-->
  <!--          :on-error="uploaderError"-->
  <!--          :on-success="uploaderSuccess"-->
  <!--          :headers="uploaderHeaders">-->
  <!--          <i class="el-icon-upload"></i>-->
  <!--          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>-->
  <!--          <div class="el-upload__tip" slot="tip">只能上传 <b>xlsx / xls</b> 文件</div>-->
  <!--        </el-upload>-->

          <div class="fileUp-title">只能上传 xlsx / xls 文件</div>
          <div class="fileUp-progress">
            <progress id="myProgress" value="0" max="100"></progress> <span id="mySpan">0%</span>
          </div>

          <div class="fileUp-smg" v-html="fileUpSmg"></div>

          <div v-if="fileUpSmg != '文件上傳完成，正在處理...'?true:false" style="margin-top: 100px;">
            <el-button id="upid" type="success" size="small" :disabled="fileUpSmg == 'loading...'?true:false"><i class="el-icon-upload"></i> 选择上传</el-button>
            <el-button id="cancel" type="danger" size="small" :disabled="fileUpSmg == 'loading...'?true:false" onclick="fileUp.cancel()">暂停</el-button>
            <el-button id="startUpload" type="warning" size="small" :disabled="fileUpSmg == 'loading...'?true:false" onclick="fileUp.startUpload()">继续</el-button>
          </div>
        </div>
      </div>

      <div class="loading-body">
        <div class="loading-bg"  v-if="loadingDb.status == 1">
          <el-image style="height: 250px" :src="loading7" fit="fit"></el-image>
          <p class="fileUp-smg">{{loadingDb.title||'正在計算數據...'}}</p>
        </div>
        <div class="loading-bg"  v-else-if="loadingDb.status == 2">
          <i class="fa fa-check-circle color-success" style="font-size: 100px;"></i>
          <p class="fileUp-smg">{{loadingDb.title}}( 已導入：<b>{{loadingDb.total}}</b> 條 )</p>
          <el-button size="small" type="warning" @click="returnBtn">返回</el-button>
        </div>
        <div class="loading-bg"  v-else-if="loadingDb.status == 3">
          <i class="fa fa-times color-danger" style="font-size: 100px;"></i>
          <p class="fileUp-smg">{{loadingDb.title}}</p>
          <el-button size="small" type="warning" @click="returnBtn">返回</el-button>
        </div>
        <div class="loading-bg"  v-else-if="loadingDb.status == 4">
          <el-image style="height: 250px" :src="loading7" fit="fit"></el-image>
          <p class="fileUp-smg">{{loadingDb.title}} ( <b>{{loadingDb.total}}</b> 條)</p>
        </div>
      </div>
    </el-card>


<!-- 导出文件 -->
<el-dialog
  title="导出文件"
  :visible.sync="dialogExport"
  width="500px"
  :before-close="handleClose">
  <el-col :span="24" style="margin-bottom:20px;">
    <el-col :span="6" style="padding:3px;">
      <el-select v-model="exportForm.storeType" placeholder="選擇門店">
        <el-option label="所有門店" value="0"></el-option>
        <el-option
          v-for="item in storeList"
          :key="item.id"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
    </el-col>
<!--    <el-col :span="6" style="padding:3px;">-->
<!--      <el-select v-model="exportForm.teamType" placeholder="選擇Team">-->
<!--        <el-option label="所有Team" value="0"></el-option>-->
<!--        <el-option label="賬戶號碼" value="1"></el-option>-->
<!--        <el-option label="手提號碼" value="2" style="margin-bottom: 20px;"></el-option>-->
<!--      </el-select>-->
<!--    </el-col>-->
    <el-col :span="12" style="padding:3px;">
      <el-date-picker
        v-model="exportForm.date"
        type="daterange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期" style="width: 100%;">
      </el-date-picker>
    </el-col>

  </el-col>

  <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="confirmExport">确 定</el-button>
  </span>
</el-dialog>



  </div>
</template>


<script>




import loading1 from '@/assets/img/admin/loading/1.gif'
import loading7 from '@/assets/img/admin/loading/7.gif'
export default {
  props: ["uploadText"],
  data() {
    return {
      fileUpSmg:"loading...",
      uploaderHeaders:{Authorization: ''},
      clientHeight: 800,
      type: "0",
      queryDb: {
        query:'',
        storeType:'',
        teamType:'',
        date:'',
        startDate:'',
        endDate:'',
      },
      advancedQuery:false,
      sexType: ["保密", "男", "女"],
      sexColor: ["info", "", "danger"],
      data: {
        total: 0,
        pageSize: 0,
        list: [],
      },
      page: 1,
      fileList:[],
      storeList:[],
      operatingUserList:[],
      groupList: [],
      multipleSelection: [],
      delAllStatus: false,
      delAllStatus2: false,
      dialogExport: false,

      exportOrImportStatus:0,
      loadingDb:{
        status:0,
        title:'',
        total:0,
        url:'',
      },
      loading1:loading1,
      loading7:loading7,
      loading: false,
      isPages:true,
      exportForm:{
        storeType:'0',
        teamType:'0',
        date:'',
        startDate:'',
        endDate:'',
      },
      // 添加/编辑
      addStatus: false,
      editStatus: false,
      form:{
        id: 0,
        adminId: 0,
        adminName:'',
        beautician: '',
        collection: 0,
        consultant: '',
        creationTime: '',
        date: '',
        holdPayment: 0,
        isDelete: 0,
        mantissa: 0,
        memberId: '',
        name: '',
        newCustomersInterimAmountOrMantissa: 0,
        newCustomersPaidIn: 0,
        no: '',
        oldCustomersInterimAmountOrMantissa: 0,
        oldCustomersPaidIn: 0,
        phones: '',
        purchaseContent: '',
        storeId: 0,
        storeName: '',
        total: '',
        operationTime:'',
      },
      rules: {
        name: [
          { required: true, message: "請填寫顧客姓名", trigger: "blur" },
        ],
        no: [
          { required: true, message: "請填寫發票號碼", trigger: "blur" },
        ],
        memberId: [
          { required: true, message: "請填寫會員號碼", trigger: "blur" },
        ],
        phones: [
          { required: true, message: "請填寫手提號碼", trigger: "blur" }
        ],
        storeId: [
          { required: true, message: "請選擇門店", trigger: "blur" },
        ],
        date: [
          { required: true, message: "請填寫日期", trigger: "blur" },
        ],
      },
    };
  },
  mounted() {
    let that = this;
    that.getList();
    that.getStoreList()
    that.getOperatingUserList()
    let userInfo = that.$store.state.userInfo
    that.uploaderHeaders = {
      Authorization: 'Bearer ' + userInfo.token
    }
    that.exportOrImportStatus = 0

  },
  methods: {
    // unix转时间格式
    unixToTime(date){
      return this.$atk.unixToTime("Y-m-d",date)
    },
    // 获取門店列表
    getStoreList() {
        let that = this;
        that.$http.GET(
          "/v1/booking/getStoreList",
          function (ret) {
            if (ret.errcode == 0) {
              let list = ret.data.list
              for(let i in list){
                that.storeList.push({
                  id:list[i].id,
                  value:list[i].id,
                  label:list[i].title,
                })
              }
            } else if (ret.errcode >= 1) {
              that.$message({
                message: ret.errmsg,
                type: "warning",
              });
            }
          },
          function (err) {
            that.$notify({
              title: "警告",
              message: err,
              type: "warning",
            });
          }
        );
      },
    // 获取運營人員列表
    getOperatingUserList() {
        let that = this;
        that.$http.GET(
          "/v1/booking/getOperatingUserList",
          function (ret) {
            if (ret.errcode == 0) {
              let list = ret.data
              for(let i in list){
                that.operatingUserList.push({
                  id:list[i].id,
                  value:list[i].id,
                  label:list[i].name,
                })
              }
            } else if (ret.errcode >= 1) {
              that.$message({
                message: ret.errmsg,
                type: "warning",
              });
            }
          },
          function (err) {
            that.$notify({
              title: "警告",
              message: err,
              type: "warning",
            });
          }
        );
      },
    // 關閉
    handleClose(close){
      close()
    },
    // 上傳xlsx失敗
    uploaderError(file) {
      this.loadingDb.status = 3
      this.loadingDb.title = file.errmsg
    },
    // 上傳xlsx成功
    uploaderSuccess(file) {
      let that = this
      if(file.errcode ==0){
        this.loadingDb.status = 1
        this.loadingDb.title = file.errmsg
        let param = that.$atk.json_to_url({
            path:file.path,
            title:file.name
        })
        let adminId = that.$store.state.userInfo.adminId
        setTimeout(function(){
          // 導入
          that.$http.GET("/v1/booking/importInvoice?" +param);
          // 檢查導出狀態
          let timeId = setInterval(function(){
            that.$http.POST("/v1/booking/checkImportInvoice",{adminId}, (ret)=> {
              if(ret.errcode == 0){
                if(ret.data.status > 1){
                    that.loadingDb = {
                      ...ret.data
                    }
                    that.getList();
                    clearTimeout(timeId);
                } else if(ret.data.status == 1){
                  that.loadingDb = {
                    ...ret.data
                  }
                }
              } else if(ret.errcode >= 1){
                clearTimeout(timeId);
                that.$notify({
                  title: "警告",
                  message: ret.errcode,
                  type: "warning",
                });
              }
            }, (err)=> {
              that.exportOrImportStatus = 0
              clearTimeout(timeId);
                that.$notify({
                  title: "警告",
                  message: err,
                  type: "warning",
                });
              }
            )
          },3000)
        },3000)
      } else if(file.errcode >= 1){
        this.loadingDb.status = 3
        this.loadingDb.title = file.errmsg
      }
    },
    // 返回按鈕
    returnBtn(){
      this.getList();
      this.fileUpSmg = "loading..."
      this.exportOrImportStatus = 0
      this.loadingDb = {
        status:0,
        total:0,
        title:'',
        url:'',
      }
    },
    // 導出、導入
    excelClick(key){
      switch (key){
        case 'export':
          this.dialogExport = true
          break;
        case 'import':
          this.returnBtn()
          this.exportOrImportStatus = 2
          break;
        case 'download':
          window.open("/static/xlsx/demo.xlsx")
          break;
      }
    },
    // 下載
    downloadFile(){
      window.open(this.loadingDb.url);
      this.exportOrImportStatus = 0
      this.loadingDb = {
        status:0,
        title:'',
        url:'',
      }
    },
    // 确认导出
    confirmExport(){
      let that = this
      let data = {
        storeType:parseInt(that.exportForm.storeType),
        teamType:parseInt(that.exportForm.teamType),
        startDate:that.exportForm.startDate,
        endDate:that.exportForm.endDate,
      }
      let param = that.$atk.json_to_url(data)
      let adminId = that.$store.state.userInfo.adminId

      if(!data.startDate){
        that.$message({
          message: "請選擇數據導出日期。",
          type: "warning",
        })
        return
      }
      that.exportOrImportStatus = 1
      that.dialogExport = false

      // 導出
      that.$http.GET("/v1/booking/exportInvoice?" +param);

      // 檢查導出狀態
      let timeId = setInterval(function(){
        that.$http.POST("/v1/booking/checkExportInvoice",{adminId}, (ret)=> {
          if(ret.errcode == 0){
            //window.open(ret.data.url);
            //if(ret.data.status > 0){
                that.loadingDb = {
                  ...ret.data
                }
                clearTimeout(timeId);
           //}
          } else if(ret.errcode >= 1){
            clearTimeout(timeId);
            that.$notify({
              title: "警告",
              message: ret.errcode,
              type: "warning",
            });
          }
        }, (err)=> {
          that.exportOrImportStatus = 0
          clearTimeout(timeId);
            that.$notify({
              title: "警告",
              message: err,
              type: "warning",
            });
          }
        )
      },3000)
    },
    // 获取列表
    getList() {
      let that = this;
      let param = that.$atk.json_to_url({
        page:parseInt(that.page),
        query:that.queryDb.query,
        storeType:that.queryDb.storeType?parseInt(that.queryDb.storeType):'',
        teamType:that.queryDb.teamType?parseInt(that.queryDb.teamType):'',
        startDate:that.queryDb.startDate,
        endDate:that.queryDb.endDate,
      });
      that.loading = true;
      this.$http.GET("/v1/booking/getInvoiceList?" + param, (ret)=> {
        that.loading = false;
        if (ret.errcode == 0) {
          let list = ret.data.list;
          let arrayDb =[]
          for(let i in list){
            let datas = {
              ...list[i],
              date:that.unixToTime(list[i].date),
              operationTime:that.unixToTime(list[i].operationTime),
            }
            arrayDb.push(datas)
          }
          that.data = {
            total: ret.data.total,
            pageSize: ret.data.pageSize,
            list:arrayDb
          }
        } else if (ret.errcode == 1) {
          that.$message({
            message: ret.errmsg,
            type: "warning",
          });
        }
      }, (err)=> {
          that.loading = false;
          that.$notify({
            title: "警告",
            message: err,
            type: "warning",
          });
        }
      );
    },
    // 分页
    pageBtn(val) {
      this.page = val;
      this.getList(val);
    },
    // 添加/编辑用户
    addToEdit(item={}) {
      let that = this;
      if (item.id > 0) {
        that.form = {
          ...item
        }
        that.addStatus = !that.addStatus;
        that.editStatus = true;
      } else {
        that.resetForm()
        that.addStatus = !that.addStatus;
        that.editStatus = false;
      }
    },
    // 获取用户信息
    getAdminInfo(id) {
      let that = this;
      let param = that.$atk.json_to_url({
        id: id,
      });
      this.$http.GET(
        "/v1/admin/getMemberInfo?" + param,
        function (ret) {
          if (ret.errcode == 0) {
            let data = ret.data || [];
            data.sex = data.sex.toString();
            data.isLock = data.isLock.toString();
            that.ruleForm = data;
          } else if (ret.errcode == 1) {
            that.$message({
              message: ret.errmsg,
              type: "warning",
            });
          }
        },
        function (err) {
          that.$notify({
            title: "警告",
            message: err,
            type: "warning",
          });
        }
      );
    },
    // 提交添加/编辑
    doAddToEdit() {
      let that = this;
      let pDate = {
        ...that.form,
        // sex: parseInt(that.ruleForm.sex),
        // isLock: parseInt(that.ruleForm.isLock),
        // groupId: parseInt(that.ruleForm.groupId),
      }

        that.$http.POST("/v1/booking/addOrEditInvoice", pDate, function (ret) {
            if (ret.errcode == 0) {
              that.$notify({
                title: "成功",
                message: ret.errmsg,
                type: "success",
              });
              that.getList();
              that.addStatus = !that.addStatus;
              that.editStatus = !that.editStatus;
            } else if (ret.errcode >= 1) {
              that.$notify.error({
                title: "错误",
                message: ret.errmsg,
              });
            }
          },
          function (err) {
            that.$notify({
              title: "警告",
              message: err,
              type: "warning",
            });
          }
        );
    },
    // 提交
    submitForm(formName) {
      let that = this;
      that.$refs[formName].validate((valid) => {
        if (valid) {
          that.doAddToEdit();
        } else {
          return false;
        }
      });
    },
    // 重置
    resetForm() {
      //this.$refs[formName].resetFields();
      this.form = {
        id: 0,
        adminId: 0,
        adminName:'',
        beautician: '',
        collection: 0,
        consultant: '',
        creationTime: '',
        date: '',
        holdPayment: 0,
        isDelete: 0,
        mantissa: 0,
        memberId: '',
        name: '',
        newCustomersInterimAmountOrMantissa: 0,
        newCustomersPaidIn: 0,
        no: '',
        oldCustomersInterimAmountOrMantissa: 0,
        oldCustomersPaidIn: 0,
        phones: '',
        purchaseContent: '',
        storeId: 0,
        storeName: '',
        total: '',
        operationTime:'',
      }
    },
    // 操作按钮
    actionBtn(command) {
      let that = this;
      if (command.name == "recycle") {
        that.$confirm("您確認要刪除吗?", "刪除提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
          .then(() => {
            that.doRecycle([command.id]);
          })
          .catch(() => {
            that.$notify({
              title: "刪除提示",
              message: "已取消刪除",
              type: "warning",
            });
          });
      } else if (command.name == "trash") {
        that
          .$confirm("您確認要刪除吗?", "删除提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
          .then(() => {
            that.doDelete(command.id);
          })
          .catch(() => {
            that.$notify({
              title: "删除提示",
              message: "已取消删除用户",
              type: "warning",
            });
          });
      }
    },
    // 设置管理人员锁定状态
    doLocked (id, isLock) {
      let that = this;
      let s = isLock =! isLock

      let param = {
        id: id,
        isLock: s?0:1
      };
      that.$http.PUT(
        "/v1/admin/locked", param,function (ret) {
          if (ret.errcode == 0) {
            that.$notify({
              title: "成功",
              message: ret.errmsg,
              type: "success",
            });
          } else if (ret.errcode == 1) {
            that.$notify.error({
              title: "错误",
              message: ret.errmsg,
            });
          }
        },
        function (err) {
          that.$notify({
            title: "警告",
            message: err,
            type: "warning",
          });
        }
      );
    },
    // 刪除發票
    doRecycle(arrayId) {
      let that = this;
      let param = that.$atk.json_to_url({
        ids: arrayId,
      });
      that.$http.DELETE(
        "/v1/booking/recycleInvoice?" + param,
        function (ret) {
          if (ret.errcode == 0) {
            that.$notify({
              title: "成功",
              message: ret.errmsg,
              type: "success",
            });
            for (let key in arrayId) {
              that.deleteItem(arrayId[key], that.data.list);
            }
            if(arrayId.length >=19){
              that.getList();
            }
          } else if (ret.errcode == 1) {
            that.$notify.error({
              title: "错误",
              message: ret.errmsg,
            });
          }
        },
        function (err) {
          that.$notify({
            title: "警告",
            message: err,
            type: "warning",
          });
        }
      );
    },
    // 删除列表用户
    deleteItem(item, list) {
      for (let key in this.data.list) {
        if (list[key].id === item) {
          list.splice(key, 1);
        }
      }
    },
    // 选择用户
    handleSelectionChange(val) {
      this.multipleSelection = val; //.filter(val => val.id!=1)
      //let row = val.filter((val) => val.id == 1);
      this.$refs.RoomTable.toggleRowSelection(row[0], false);
    },
    // 回收已选择用户
    doRecycleAll() {
      let that = this;
      let length = that.multipleSelection.length;
      if (length <= 0) {
        that.$notify({
          title: "刪除提示",
          message: "請勾選需要刪除的發票。",
          type: "warning",
        });
        that.delAllStatus = false;
        return;
      }
      let idList = [];
      for (let i in that.multipleSelection) {
        idList[i] = that.multipleSelection[i].id;
      }
      that.doRecycle(idList);
      that.delAllStatus = false;
    },
    // 查询
    queryBin() {
      let that = this;
      if(that.advancedQuery){
        if (!that.queryDb.storeType) {
          that.$message({
            message: "請選擇查詢門店。",
            type: "warning",
          });
          return;
        } else if (!that.queryDb.teamType) {
          that.$message({
            message: "請選擇查詢Team。",
            type: "warning",
          });
          return;
        }
      } else{
        if (!that.queryDb.query) {
          that.$message({
            message: "請填寫查詢內容。",
            type: "warning",
          });
          return;
        }
      }
      that.page = 1
      this.isPages = false;
      this.$nextTick(() => {
        this.isPages = true;
      });
      that.getList();
    },
  },
  watch:{
    "exportForm.date":function(){
      this.exportForm.startDate = this.$atk.formatDate(this.exportForm.date[0])
      this.exportForm.endDate = this.$atk.formatDate(this.exportForm.date[1])
    },
    "queryDb.date":function() {
        this.queryDb.startDate = this.queryDb.date[0]?this.$atk.formatDate(this.queryDb.date[0]):''
        this.queryDb.endDate = this.queryDb.date[1]?this.$atk.formatDate(this.queryDb.date[1]):''
    },
    "advancedQuery":function(){
      //that.queryDb.startDate
      this.queryDb = {
        query:'',
        storeType:'0',
        teamType:'0',
        date:'',
        startDate:'',
        endDate:'',
      }
    },
    "exportOrImportStatus":function () {
      if(this.exportOrImportStatus == 2){
        let that = this
        let headers = this.uploaderHeaders
        let adminId = this.$store.state.userInfo.adminId
        function uploaderSuccess(url) {
          setTimeout(function () {
            // 導入
            that.$http.GET("/v1/booking/importInvoice?path=./" + url);

            // 檢查導入狀態
            let timeId = setInterval(function(){
              that.$http.POST("/v1/booking/checkImportInvoice",{adminId}, (ret)=> {
                  if(ret.errcode == 0){
                    let data = {
                      status:ret.data.status?parseInt(ret.data.status):0,
                      total:ret.data.total?parseInt(ret.data.total):0,
                      title:ret.data.title
                    }
                    if(data.status == 2 || data.status == 3){
                      that.loadingDb = {
                        ...data
                      }
                      that.getList();
                      clearTimeout(timeId);
                    } else if(data.status == 1 || data.status == 4){
                      that.loadingDb = {
                        ...data
                      }
                    }
                  }
                }, (err)=> {
                  that.exportOrImportStatus = 0
                  clearTimeout(timeId);
                  that.$notify({
                    title: "警告",
                    message: err,
                    type: "warning",
                  });
                }
              )
            },3000)
          },10000)
        }
        setTimeout(function () {
          // 进度条
          function Progress(value) {
            let myProgress = document.getElementById("myProgress");
            let mySpan = document.getElementById("mySpan");
            mySpan.innerText = value + "%";
            myProgress.value = value;
          }
          let fileUp = new fcup({
            id: "upid", // 绑定id
            url: "/v1/booking/largeUpload", // url地址
            checkurl: "/v1/booking/checkLargeUpload", // 检查上传url地址
            type: "xlsx,xls", // 限制上传类型，为空不限制
            shardsize: "2", // 每次分片大小，单位为M，默认1M
            minsize: "", // 最小文件上传M数，单位为M，默认为无
            maxsize: "10240", // 上传文件最大M数，单位为M，默认200M
            headers: headers, // 附加的文件头,默认为null, 请注意指定header头时将不能进行跨域操作
            //apped_data: {}, //每次上传的附加数据
            // 定义错误信息
            errormsg: {
              1000: "未找到上传id",
              1001: "类型不允许上传",
              1002: "上传文件过小",
              1003: "上传文件过大",
              1004: "上传请求超时"
            },
            // 错误提示
            error: (msg) => {
              if(msg == "未找到上传id"){
                that.exportOrImportStatus=0
                return
              }
              that.$message({
                message: msg,
                type: "warning",
              });
            },
            // 初始化事件
            start: () => {
              that.fileUpSmg = "上传已准备就绪"
              Progress(0);
            },
            // 等待上传事件，可以用来loading
            beforeSend: () => {
              that.fileUpSmg = "等待请求中"
            },
            // 上传进度事件
            progress: (num, other) => {
              Progress(num);
            },
            // 检查地址回调,用于判断文件是否存在,类型,当前上传的片数等操作
            checksuccess: (res) => {
              let data = res ? JSON.parse(res) : {};
              let code = data.code;
              let msg = data.msg;
              let url = data.data.url;
              // 错误提示
              if (code == 1 ) {
                that.$message({
                  message: msg,
                  type: "warning",
                });
                return false;
              }
              // 已经上传
              if (code == 2) {
                Progress(100);
                that.fileUpSmg = "文件上傳完成，正在處理..."
                setTimeout(function() {
                  uploaderSuccess(url)
                },2000)
                return false;
              }
              // 如果提供了这个参数,那么将进行断点上传的准备
              if(data.data.index){
                // 起始上传的切片要从1开始
                let file_index = data.data.index ? parseInt(data.data.index) : 1;
                // 设置上传切片的起始位置
                fileUp.setshard(file_index);
              }
              // 如果接口没有错误，必须要返回true，才不会终止上传
              return true;
            },
            // 上传成功回调，回调会根据切片循环，要终止上传循环，必须要return false，成功的情况下要始终返回true;
            success: (res) => {
              let data = res ? JSON.parse(res) : {};
              let url = data.data.url;
              let file_index = data.data.index ? parseInt(data.data.index) : 1;
              if (data.code == 2) {
                that.fileUpSmg = "文件上傳完成，正在處理..."
                setTimeout(function() {
                  uploaderSuccess(url)
                },2000)
              }
              // 如果接口没有错误，必须要返回true，才不会终止上传循环
              return true;
            }
          })
        },1000)
      }
    }
  }
};
</script>

<style scoped lang="scss">
/*fileUp*/
.fileUp-title,.fileUp-smg{
  font-size: 13px;
  color: #515a6e;
}
.fileUp-progress{
  padding: 43px 0 10px 0;
}
.fileUp-smg{
  padding: 12px 0 12px 0;
  b{
    font-weight: 800;
    font-size: 16px;
    color: #59ba59;
  }
}
progress{
  width: 250px;
  -webkit-appearance: none;
}
::-webkit-progress-bar{
  background-color: #ccc;
}
::-webkit-progress-value {
  /*background-color: rgb(, , );*/
}
::-webkit-progress-inner-element {
  /*border: px solid black;*/
  /* 进度条的内边框，注意跟outline区分 */
}




.upload-block {
  text-align: center;
  line-height: 80px;
  height: 80px;
  .el-upload {
    width: 100%;
    height: 100%;
    .el-upload-dragger {
      width: 100%;
      height: 100%;
      .el-upload__text {
        position: relative;
        font-size: 1.2em;
        color: #409eff;
        i {
          font-size: 1.5em;
          margin-right: 10px;
        }
      }
    }
  }
}
.box-card {
  min-height: 10px;
  .listsHeader {
    min-height: 50px;
    .advancedQuery{
      padding: 12px 0 12px 0;
    }
  }
  .pager {
    padding-top: 8px;
  }
  .loading-body{
    width: 100%;
    display: inline-block;
    text-align: center;
    position: relative;
    padding: 0px 0 30px 0;
    .loading-bg{
      width: 300px;
      height: 300px;
      margin:0 auto;
      border: 1px dashed #e6e7e7;
      border-radius: 6px;
      padding: 20px;
      cursor: pointer;
    }
    .loading-bg:hover{
      border: 1px dashed #81c0f1;
    }
  }

}
.employees-input {
  .queryBtn{
    button,.bg-primary,.bg-warning{
      height: 100%;
    }
    button {
      border-radius: 0;
      color: #fff;
      background-color: #25334e;
    }
    button:hover {
      background-color: #182132;
    }
    .bg-primary{
      margin: 0 -20px 0 20px;
      border-radius: 0px 3px 3px 0px;
      background-color: #409EFF;
    }
    .bg-primary:hover {
      background-color: #1e84eb;
    }
    .bg-warning{
      margin: 0 -20px 0 20px;
      border-radius: 0px 3px 3px 0px;
      background-color: #E6A23C;
    }
    .bg-warning:hover {
      background-color: #e4951e;
    }
  }
}
.table-user-head {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}
/* 列表 */
.listsHeader{
  .el-input-group__prepend .el-select {
    width: 110px;
  }
}
/* 编辑或者添加 */
.member-ruleForm{
  .avatar-uploader {
    .el-upload {
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    .avatar {
      width: 178px;
      height: 178px;
      display: block;
      border-radius: 6px;
    }
  }
}
</style>
